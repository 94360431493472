import "core-js/stable";
import Vue from "vue";
import App from "./App";
import router from "./router";
import CoreuiVue from "@coreui/vue";
import { iconsSet as icons } from "./assets/icons/icons.js";
import store from "./store";
import VueCookies from "vue-cookies";
import CoreuiVueCharts from "@coreui/vue-chartjs";
import configs from "./configs";
import configs_accred from "./configs_accred";
import configs_ruvents_dev from "./configs_ruvents_dev";
import configs_crki_art from "./configs_crki_art";
import configs_accred_ruvents_dev from "./configs_accred_ruvents_dev";
const vueConfig = require("vue-config");

Vue.use(VueCookies);
Vue.use(CoreuiVueCharts);
// set default config
Vue.$cookies.config("2d");

Vue.config.performance = true;
Vue.use(CoreuiVue);
Vue.prototype.$log = console.log.bind(console);

if (window.location.hostname == "partner.accred.ru") {
  Vue.use(vueConfig, configs_accred);
  document.title = configs_accred.Title;
} else if (window.location.hostname == "partner.ruvents.dev") {
  Vue.use(vueConfig, configs_ruvents_dev);
  document.title = configs_ruvents_dev.Title;
} else if (window.location.hostname == "accred.crki.art") {
  Vue.use(vueConfig, configs_crki_art);
  document.title = configs_crki_art.Title;
} else if (window.location.hostname == "partner.accred.ruvents.dev") {
  Vue.use(vueConfig, configs_accred_ruvents_dev);
  document.title = configs_accred_ruvents_dev.Title;
} else {
  Vue.use(vueConfig, configs);
  document.title = configs.Title;
}

new Vue({
  el: "#app",
  router,
  store,
  icons,
  template: "<App/>",
  components: {
    App,
  },
});
