const configs = {
  API: "https://partner.accred.ruvents.dev/",
  IMG: "https://accred.ruvents.dev/storage/",
  isTavrida: false,
  Title: "Тестовый сервер. Партнерка",
  QContact: "https://qcontact.ru/",
  ServerName: "accred_ruvents_dev",
  ContractorsUrl: "https://contractors.accred.ruvents.dev/",
};
export default configs;
