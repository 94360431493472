const configs = {
  API: "https://partner.accred.ru/",
  IMG: "https://storage.yandexcloud.net/s3.accred.ru/",
  isTavrida: false,
  Title: "Управление мероприятием",
  QContact: "https://qcontact.ru/",
  ServerName: "accred_ru",
  ContractorsUrl: "https://contractors.accred.ru",
};
export default configs;
