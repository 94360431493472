const configs = {
  API: "https://api2.ruvents.dev/",
  IMG: "https://storage.yandexcloud.net/s3.accred.ru/",
  isTavrida: true,
  Title: "Тестовый сервер. Партнерка",
  QContact: "https://qcontact.ru/",
  ServerName: "accred_ruvents_dev",
  ContractorsUrl: "https://contractors.accred.ruvents.dev/",
};
export default configs;
