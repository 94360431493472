const configs = {
  API: "https://accred.crki.art/",
  IMG: "https://accred.crki.art/storage/",
  isTavrida: true,
  Title: "Партнерка ИС “Академия”",
  QContact: "https://qcontact.ru/",
  ServerName: "accred_crki_art",
  ContractorsUrl: "https://contractors.crki.art",
};
export default configs;
